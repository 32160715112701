import '../css/App.css';
import React from 'react';
import Mean from './Mean.js';
import Table from './Table.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class App extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return <React.Fragment>
    <Router>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="android-chrome-192x192.png" alt="Rybomer" width="40" height="40" class="d-inline-block align-text-top" />
        </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <Link class="nav-link" to="/">Mean</Link>
              <Link class="nav-link" to="/table">Table</Link>
            </div>
          </div>
        </div>
      </nav>

      <div className="container pt-1" id="content-container">
        <Switch>
          <Route path="/table">
            <Table />
          </Route>
          <Route path="/">
            <Mean />
          </Route>
        </Switch>
      </div>
    </Router>
    </React.Fragment>;
  }
}

export default App;
