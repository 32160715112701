import React from 'react';

function Table(props) {
  return <div>
  <input type="text" className="form-control" placeholder="Rows" />
  <input type="text" className="form-control" placeholder="Columns" />
  <table className="table">
    <thead>
      <tr>
        <th>Fish</th><th>1</th><th>Summary</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>AAA</td><td>100%</td><td>1000</td>
      </tr>
    </tbody>
  </table>
  </div>;
}

export default Table;
