import React from 'react';

class Mean extends React.Component{
  constructor(props){
    super(props);
    this.state={
      count: 7,
      scope: 100,
      average: 350,
      items: []
    };
  }

  handleChange(e,vname){
    switch(vname) {
      case 'average': this.setState({average: Number(e.target.value)}, () => this.calcx()); break;
      case 'count': this.setState({count: Number(e.target.value)}, () => this.calcx()); break;
      case 'scope': this.setState({scope: Number(e.target.value)}, () => this.calcx()); break;
      default: return;
    }
  }

  calcMean(items){
    const arr = items;
    const sum = arr.reduce((a, b) => a + b, 0);
    const avg = (sum / arr.length) || 0;
    return avg;
  }

  calcx(e){
    const lower=this.state.average-this.state.scope;
    const upper=this.state.average+this.state.scope;
    let items=[];
    for (let i=1; i<=this.state.count; i++) {
      items.push(Math.floor(Math.random() * (upper - lower + 1) + lower));
    }
    console.log('GENERATED:');
    console.log(items);
    var mean;
    var i=0;
    var randomPointer;
    while (true) {
      mean = this.calcMean(items);
      if (mean==this.state.average) break;
      else {
        randomPointer=Math.floor(Math.random() * items.length);
        console.log(randomPointer);
        if (mean>=this.state.average) {
          while(items[randomPointer]>=upper) {
            console.log(items[randomPointer]+'>='+upper);
            randomPointer=Math.floor(Math.random() * items.length);
            console.log('Next item: '+items[randomPointer]);
          }
          items[randomPointer]--;
        }
        if (mean<=this.state.average) {
          while(items[randomPointer]<=lower) randomPointer=Math.floor(Math.random() * items.length);
          items[randomPointer]++;
        }
      }
      i++;
      if (i==1000) {
        alert('Too many iterations. Try again.');
        break;
      }
    }
    console.log('IMPROVED:');
    console.log(items);
    this.setState({
      lower: lower,
      upper: upper,
      items: items
    });
  }

  componentDidMount(){
    this.calcx();
  }

  render(){
    return <div className="row">
      <div className="input col-12 col-md-6">
        <div className="input-group mb-3">
          <span className="input-group-text" id="average">Average</span>
          <input type="number" onChange={(e) => this.handleChange(e, 'average')} value={this.state.average} className="form-control" placeholder="average" aria-label="average" aria-describedby="average"/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="count">Count</span>
          <input type="number" onChange={(e) => this.handleChange(e, 'count')} value={this.state.count} className="form-control" placeholder="items count" aria-label="count" aria-describedby="count"/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="scope">Scope</span>
          <input type="number" onChange={(e) => this.handleChange(e, 'scope')} value={this.state.scope} className="form-control" placeholder="scope" aria-label="scope" aria-describedby="scope"/>
        </div>
        <button className="btn btn-large btn-success" onClick={(e) => this.calcx(e)}>Calc</button>
      </div>
      <div className="output col-12 col-md-6">
        <div>Limits from <b>{this.state.lower}</b> to <b>{this.state.upper}</b></div>
        <div>
        <ol>
        {
          this.state.items.map((val, key) => <li key={key}>{val}</li>)
        }
        </ol>

        Average check: {this.calcMean(this.state.items)}
        </div>
      </div>
    </div>;
  }
}

export default Mean;
